import {showHide} from './show_hide';

// navigation
window.navBurger = function() {
  const topNav = document.getElementById("myTopnav");
  if (topNav.className === "topnav") {
    topNav.className += " responsive";
  } else {
    topNav.className = "topnav";
  }
}

$( document ).ready(function() {
  $('.tab-button').on('click', function() {
    var $tabContainer = $(this).closest('.tab');
    var activeTabId = $(this).find('.tablinks').attr('id');

    $tabContainer.find('.tablinks').each(function () {
      var $tabButton = $(this).closest('.tab-button');
      if ($(this).attr('id') === activeTabId) {
        $tabButton.addClass('active');
      } else {
        $tabButton.removeClass('active');
      }
    });

    $('.tabcontent').hide();
    $('#' + activeTabId +'content').css('display','block');
  });

  $('.tab-button.active').first().click();

  showHide($('body')[0]);
});
