function showHide(el) {
  const $root = $(el);

  $root.find('.show-hide-more-link, .show-hide-less-link').on('click', function (e) {
    e.preventDefault();

    const $showHide = $(this).closest('.show-hide');
    const $hiddenContent = $showHide.find('.show-hide-content');
    const hiddenContentVisible = !$hiddenContent.hasClass('hidden');
    const truncateText = $showHide.find('.text-truncate'); // (...)

    if (hiddenContentVisible) {
      $hiddenContent.addClass('hidden');
      truncateText.addClass('truncated');
      $showHide.find('.show-hide-more-link').removeClass('hidden');
      $showHide.find('.show-hide-less-link').addClass('hidden');
    } else {
      truncateText.removeClass('truncated');
      $hiddenContent.removeClass('hidden');
      $showHide.find('.show-hide-more-link').addClass('hidden');
      $showHide.find('.show-hide-less-link').removeClass('hidden');
    }
  });
}

export {showHide};
